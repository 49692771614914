import { addPropertyControls, ControlType } from "framer"

// Full divorce data set
const divorceData = {
    Alabama: { filingFees: 225, costWithKids: 14117, costWithoutKids: 9338 },
    Alaska: { filingFees: 150, costWithKids: 14042, costWithoutKids: 9188 },
    Arizona: { filingFees: 256, costWithKids: 13997, costWithoutKids: 9218 },
    Arkansas: { filingFees: 150, costWithKids: 11138, costWithoutKids: 7315 },
    California: {
        filingFees: 443,
        costWithKids: 19639,
        costWithoutKids: 12948,
    },
    Colorado: { filingFees: 230, costWithKids: 15318, costWithoutKids: 10062 },
    Connecticut: {
        filingFees: 360,
        costWithKids: 16820,
        costWithoutKids: 11086,
    },
    Delaware: { filingFees: 155, costWithKids: 16625, costWithoutKids: 10891 },
    "District of Columbia": {
        filingFees: 120,
        costWithKids: 13797,
        costWithoutKids: 9018,
    },
    Florida: { filingFees: 380, costWithKids: 14126, costWithoutKids: 9347 },
    Georgia: { filingFees: 200, costWithKids: 15488, costWithoutKids: 10232 },
    Hawaii: { filingFees: 240, costWithKids: 12610, costWithoutKids: 8259 },
    Idaho: { filingFees: 207, costWithKids: 11180, costWithoutKids: 7304 },
    Illinois: { filingFees: 299, costWithKids: 14051, costWithoutKids: 9272 },
    Indiana: { filingFees: 153, costWithKids: 12502, costWithoutKids: 8201 },
    Iowa: { filingFees: 265, costWithKids: 12529, costWithoutKids: 8228 },
    Kansas: { filingFees: 177, costWithKids: 11373, costWithoutKids: 7550 },
    Kentucky: { filingFees: 150, costWithKids: 11124, costWithoutKids: 7301 },
    Louisiana: { filingFees: 325, costWithKids: 13917, costWithoutKids: 9138 },
    Maine: { filingFees: 120, costWithKids: 11093, costWithoutKids: 7270 },
    Maryland: { filingFees: 165, costWithKids: 15253, costWithoutKids: 9997 },
    Massachusetts: {
        filingFees: 200,
        costWithKids: 16660,
        costWithoutKids: 10926,
    },
    Michigan: { filingFees: 203, costWithKids: 13972, costWithoutKids: 9113 },
    Minnesota: { filingFees: 405, costWithKids: 12710, costWithoutKids: 8409 },
    Mississippi: { filingFees: 63, costWithKids: 11373, costWithoutKids: 7550 },
    Missouri: { filingFees: 170, costWithKids: 13950, costWithoutKids: 9072 },
    Montana: { filingFees: 238, costWithKids: 8400, costWithoutKids: 5533 },
    Nebraska: { filingFees: 158, costWithKids: 11131, costWithoutKids: 7308 },
    Nevada: { filingFees: 225, costWithKids: 14016, costWithoutKids: 9155 },
    "New Hampshire": {
        filingFees: 251,
        costWithKids: 12745,
        costWithoutKids: 8444,
    },
    "New Jersey": {
        filingFees: 300,
        costWithKids: 16760,
        costWithoutKids: 11026,
    },
    "New Mexico": {
        filingFees: 145,
        costWithKids: 9053,
        costWithoutKids: 5947,
    },
    "New York": {
        filingFees: 210,
        costWithKids: 18853,
        costWithoutKids: 12401,
    },
    "North Carolina": {
        filingFees: 230,
        costWithKids: 13867,
        costWithoutKids: 9013,
    },
    "North Dakota": {
        filingFees: 80,
        costWithKids: 11053,
        costWithoutKids: 7230,
    },
    Ohio: { filingFees: 313, costWithKids: 12695, costWithoutKids: 8394 },
    Oklahoma: { filingFees: 213, costWithKids: 12528, costWithoutKids: 8227 },
    Oregon: { filingFees: 301, costWithKids: 14018, costWithoutKids: 9239 },
    Pennsylvania: {
        filingFees: 250,
        costWithKids: 15290,
        costWithoutKids: 10034,
    },
    "Rhode Island": {
        filingFees: 160,
        costWithKids: 14117,
        costWithoutKids: 9338,
    },
    "South Carolina": {
        filingFees: 150,
        costWithKids: 13867,
        costWithoutKids: 9088,
    },
    "South Dakota": {
        filingFees: 95,
        costWithKids: 11754,
        costWithoutKids: 7692,
    },
    Tennessee: { filingFees: 264, costWithKids: 13290, costWithoutKids: 8676 },
    Texas: { filingFees: 300, costWithKids: 17456, costWithoutKids: 11446 },
    Utah: { filingFees: 320, costWithKids: 14590, costWithoutKids: 9621 },
    Vermont: { filingFees: 193, costWithKids: 12435, costWithoutKids: 8134 },
    Virginia: { filingFees: 150, costWithKids: 15858, costWithoutKids: 10363 },
    Washington: { filingFees: 314, costWithKids: 14031, costWithoutKids: 9252 },
    "West Virginia": {
        filingFees: 160,
        costWithKids: 11107,
        costWithoutKids: 7284,
    },
    Wisconsin: { filingFees: 200, costWithKids: 11854, costWithoutKids: 7782 },
    Wyoming: { filingFees: 120, costWithKids: 12430, costWithoutKids: 8129 },
}

// Divorce Cost Calculator Component
export default function DivorceCostCalculator() {
    const [selectedState, setSelectedState] = React.useState("")
    const [hasKids, setHasKids] = React.useState(false)

    const ourCost = 1999

    const calculateTraditionalCost = () => {
        if (!selectedState) return 0
        const stateData = divorceData[selectedState]
        return hasKids ? stateData.costWithKids : stateData.costWithoutKids
    }

    const traditionalCost = calculateTraditionalCost()
    const savings = traditionalCost - ourCost

    return (
        <div
            style={{
                width: "100%",
                padding: "20px",
                fontFamily: "Arial, sans-serif",
            }}
        >
            <h2 style={{ fontSize: "24px", marginBottom: "20px" }}>
                Divorce Cost Calculator
            </h2>
            <p style={{ marginBottom: "20px" }}>
                Compare the cost of traditional divorce with our services
            </p>

            <div>
                <label style={{ display: "block", marginBottom: "5px" }}>
                    Select your state:
                </label>
                <select
                    style={{
                        width: "100%",
                        padding: "10px",
                        marginBottom: "20px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                    }}
                    onChange={(e) => setSelectedState(e.target.value)}
                    value={selectedState}
                >
                    <option value="">Choose a state</option>
                    {Object.keys(divorceData).map((state) => (
                        <option key={state} value={state}>
                            {state}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                <label style={{ display: "block", marginBottom: "5px" }}>
                    Do you have kids?
                </label>
                <select
                    style={{
                        width: "100%",
                        padding: "10px",
                        marginBottom: "20px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                    }}
                    onChange={(e) => setHasKids(e.target.value === "yes")}
                    value={hasKids ? "yes" : "no"}
                >
                    <option value="">Select an option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>

            {selectedState && (
                <div style={{ marginTop: "20px" }}>
                    <h3 style={{ fontSize: "18px", marginBottom: "10px" }}>
                        Cost Comparison:
                    </h3>
                    <p>
                        Traditional Divorce Cost: $
                        {traditionalCost.toLocaleString()}
                    </p>
                    <p>Our Service Cost: ${ourCost.toLocaleString()}</p>
                    <p style={{ fontWeight: "bold" }}>
                        Potential Savings: ${savings.toLocaleString()}
                    </p>
                </div>
            )}

            <div style={{ marginTop: "30px", fontSize: "12px", color: "#666" }}>
                <h4 style={{ fontWeight: "bold", marginBottom: "5px" }}>
                    Disclaimer:
                </h4>
                <p>
                    This calculator provides estimated costs for informational
                    purposes only. It does not constitute legal advice or
                    guarantee exact costs. Actual divorce expenses may vary
                    based on individual circumstances. Our services may not be
                    equivalent to traditional divorce proceedings in all
                    aspects. Please consult with a licensed attorney in your
                    state for legal advice.
                </p>
            </div>
        </div>
    )
}

// Add property controls for Framer
addPropertyControls(DivorceCostCalculator, {
    // Add any customizable properties here if needed
})
